.notificationAlignment{
    display:flex;
}
.ap-switch.NotificationToggle {
    width: 2rem;
}
.ap-switch .ap-switch-btn.ap-switch-checked {
    background-color:#e0301e;
}
.ql-editor {
    background-color: #fff;
}