.bg-blk {
  background-color: #000;
}

.ap-footer {
  color: #696969 !important;
}

.ap-header-title {
  color: white !important;
}

.ap-search-container{
  padding: 0 !important;
}

.sc-crozmw.jlPZUL {
  color: #252525;
}

.expand-container {
  background-color: #DDDDDD;
  padding: 5px 50px 5px 60px;
  font-size: 13px;
}

.rdt_TableHeadRow > div {
  background-color: #f2f2f2;
}
.login-button-color {
  background: #000 !important;
  border: #000 !important;
  color: #fff;
  border-radius: 3px;
}

.content-body {
  margin-left: 280px;
  overflow-x: auto;
}

.access-warning {
  font-size: larger;
  margin-left: 16px;
  color: #e0301e;
}

.content-body-collapsed {
  margin-left: 72px;
}

.disabled-field {
  opacity: 0.5;
}

.ap-avatar {
  background-color: #e0301e !important;
  min-width: 40px !important;
}

.ap-header-options-item {
  color: white !important;
}

.ap-header .ap-header-options {
  display: flex;
  column-gap: var(--spacing-4);
  align-items: center;
}

.ap-header-options-item.hamburger {
  color: black !important;
}

.ap-footer .ap-footer-link-group {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  justify-content: space-evenly;
  align-items: center;
}

.fixed-bottom.width {
  margin-left: 280px;
}
.fixed-bottom.sidenav {
  margin-left: 72px;
}
.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.ap-link {
  color: #696969 !important;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
.content-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.ap-footer-content {
  width: 550px !important;
}

.ap-progress-stepper-wrapper .ap-progress-stepper-btn .upcoming-status {
  opacity: 1 !important;
}

.a-apply-theme-background-gray-level-1 > div {
  box-shadow: 0 0 0.03rem black;
}
.scopeList > div {
  box-shadow: 0 0 1px black;
}

.ap-badge-review {
  background-color: #d04a02;
}

.ap-badge.ap-badge-warning {
  background-color: #ffb600;
  color: #252525 !important;
}

.ap-footer .ap-footer-link-group .ap-footer-link .ap-footer-divider {
  display: none;
}

.error-container {
  padding: 20px;
  color: #e0301e;
}
.cancelBtn {
  color: #e0301e !important;
  box-shadow: none !important;
  border: 1px solid #e0301e !important;
  border-radius: 1px;
}
.backButton {
  float: right;
  margin-right: 5px;
  border: 1px solid #e0301e !important;
  color: #e0301e !important;
  box-shadow: none !important;
  border-radius: 1px;
}

.ap-tabset-container .ap-tabset-toggle-wrapper.filled .ap-tabset-toggle.active {
  background-color: #e0301e;
}

.icon-color {
  color: #e0301e;
}

.btnAlign {
  float: right;
  outline: none !important;
  margin: 0 5px;
  margin-right: auto;
}
.colorOutline {
  background: #e0301e !important;
  outline: none !important;
  border-radius: 1px;
}

.ap-tabset-toggle-wrapper.filled {
  background-color: #ffffff !important;
}

.ap-accordion .ap-accordion-cursor-default > * {
  padding: 10px;
}
.ap-accordion .ap-accordion-content {
  padding: 0 !important;
}

.mar-top-10 {
  margin-top: 10px;
}

.Appkit4-icon.icon-delete-fill {
  padding: 2px;
}
.Appkit4-icon.icon-view-fill {
  padding: 2px;
}
.Appkit4-icon.icon-edit-outline {
  padding: 2px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.ap-progress-stepper-wrapper .ap-progress-stepper-bar-inner {
  background-color: #e0301e;
}
.ap-progress-stepper-wrapper .ap-progress-stepper-btn .normal-status {
  color: #e0301e;
}
.ap-fileupload
  .ap-fileupload-wrapper
  .ap-fileupload-content-wrapper
  .ap-fileupload-content
  .ap-fileupload-drop-btn
  .ap-fileupload-drop-browse-span {
  color: #e0301e;
}

.ap-progress-stepper-wrapper .ap-progress-stepper {
  width: 100% !important;
}

.ap-progress-stepper-wrapper {
  width: 100% !important;
}

.action-l-container {
  width: 100%;
  display: flex;
  justify-content: end;
  padding: 0 7px;
}

.ap-fileupload
  .ap-fileupload-wrapper
  .ap-fileupload-footer-delete-attachment
  span {
  color: #e0301e;
}
.tersAndCondition .icon-checkbox-backdrop-outline .icon-checkbox-selected-fill {
  color: #000;
}
.ap-checkbox-container:not(.tersAndCondition)
  .ap-checkbox
  .icon-checkbox-backdrop-outline
  .icon-checkbox-selected-fill {
  color: #e0301e;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #e0301e !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #e0301e !important;
}

.ap-badge.ap-badge-primary {
  background-color: #d04a02 !important;
}

.textFieldWidth {
  height: 45px;
}

.icon-radio-selected-fill {
  color: #e0301e !important;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  width: 5em !important;
}
.disabled_button {
  pointer-events: none;
  opacity: 0.5;
}

.disabe_cursor {
  cursor: not-allowed !important;
}

.ap-accordion.expanded .ap-accordion-content.ap-accordion-cursor-default {
  height: auto !important;
}

.footer-link-item {
  list-style: none;
  padding-bottom: 6px;
}
.footer-link-item li {
  display: inline;
  margin-left: 10px;
}

.ap-button-negative {
  background-color: #e0301e !important;
  outline: none !important;
}
.feedbackAlignment {
  margin-left: 15px;
  margin-right: 20px;
}
.alertAlignment {
  margin-left: 19px;
  margin-right: 20px;
}

.rdt_TableCell,
.ant-input,
.ant-select,
.ant-select-selection-item {
  font-family: "PwC Helvetica Neue";
  font-size: 13px;
  line-height: 1.7em;
  font-weight: "Normal";
}
.rdt_TableCol,
.ant-table-cell {
  font-family: "PwC Helvetica Neue";
  font-size: 13px;
  line-height: 1.4em;
  font-weight: "Bold";
}

.ap-field-input,
.ap-option-label,
.ap-combobox-input,
.deleteText,
.combobox-label,
.scope-date,
.select__input-container,
.select__placeholder,
.select__value-container,
.select__control,
.select__input {
  font-size: 16px !important;
  line-height: 1.7em;
  font-family: "PwC Helvetica Neue";
  font-weight: normal;
}
.ap-field-title,
.ap-field-placeholder,
.selected-label {
  font-size: 100%;
  line-height: 1.7em;
  font-family: "PwC Helvetica Neue";
  font-weight: normal;
}
.ap-tabset-toggle {
  font-size: 16px !important;
  font-family: "PwC Helvetica Neue";
  line-height: 1.4em;
}
.ap-progress-stepper-btn-text,
.pageContentHeading,
.ap-modal-title {
  font-size: 16px !important;
  font-family: "PwC Helvetica Neue";
  line-height: 1.4em;
  font-weight: bold !important;
  word-wrap: break-word;
}

.label-wrap {
  text-wrap: balance;
}

.ap-breadcrumb-item {
  font-size: 18px !important;
  font-family: "PwC Helvetica Neue";
  font-weight: bold;
  line-height: 1.4em;
}

.ap-radio .icon-radio-backdrop-outline .icon-radio-selected-fill {
  color: #e0301e;
}
.calendarPicker .ap-field {
  width: 100% !important;
}
.excelUpload a {
  background-color: #fff;
  min-width: 32px;
  display: contents !important;
}
.simple-editor-container {
  width: rem(460px);
}
