.headers{
    font-size: 22px;
    font-weight: 500;
    color: rgb(45,45,45);
}
.entireContent{
    margin-left: 2%;
    margin-right: 3%;
}
.bodyCookie{
    margin-top: 1%;
    font-size: 14px;
    line-height: 1.7;
    font-weight: 400;
    text-align: justify;
    color: rgb(125,125,125);
}
.endContent{
    margin-top: 2%;
}