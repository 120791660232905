.columnControl .collectionv2-component.container {
    width: 100%
}
.cmp-base-modernized .columnControl .asset-sharing-component.image-asset {
    width: 100%
}
.cmp-base-modernized.standard-spacing .parsys.sectionpar .columnControl:nth-last-child(1) {
    margin-bottom: 40px
}

@media(max-width: 767px) {
    .cmp-base-modernized.standard-spacing .parsys.sectionpar .columnControl:nth-last-child(1) {
        margin-bottom:20px
    }
}
.text .text-component ul,.text .text-component ol,.textimage .textimage-text ul,.textimage .textimage-text ol,.text.is-edited {
    display: block;
    padding-left: 30px
}

.text .text-component ul ul,.text .text-component ul ol {
    padding-left: 25px
}

.text .text-component ol ul,.text .text-component ol ol {
    padding-left: 32px
}

.text-component ul,.text-component ol {
    margin-bottom: 10px;
    padding-left: 20px
}

.text-component ul ul,.text-component ul ol,.text-component ol ul,.text-component ol ol {
    margin-top: 15px
}

.text-component ul ul li,.text-component ul ol li,.text-component ol ul li,.text-component ol ol li {
    margin-bottom: 11px
}

.text-component ul li p {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: top
}

.text-component .btn {
    max-width: 64ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

@media screen and (max-width: 468px) {
    .text-component .btn {
        max-width:100%
    }
}

.text-component p .btn.btn--transparent {
    margin-right: 25px
}

.text-component p .btn.btn--transparent.btn-cta {
    color: #fff;
    border: 0
}

@media(min-width: 1200px) {
    .text-component p .btn.btn--transparent.btn-cta {
        min-height:49px
    }
}
.parsys_column .image_left.text_quote_v2 .textimage-text,.parsys_column .image_right.text_quote_v2 .textimage-text {
    width: 100%
}

.parsys_column .image_left.text_quote_v2 .textimage-image,.parsys_column .image_right.text_quote_v2 .textimage-image {
    margin-top: 5px
}

.parsys_column .image_left.text_quote_v2 .textimage-text p,.parsys_column .image_right.text_quote_v2 .textimage-text p {
    display: inline !important;
    border-top: none !important
}

.parsys_column .image_right.text_quote_v2,.parsys_column .image_left.text_quote_v2 {
    border-top: 2px dotted;
    padding-top: 5px;
    margin-top: 8px
}
@media(max-width: 640px) {
    .parsys_column.pwccol1-longform .parsys_column .text-component,.parsys_column.pwccol1-longform-left .parsys_column .text-component,.parsys_column.pwccol2-longform .parsys_column .text-component,.parsys_column.pwccol2-longformb .parsys_column .text-component,.parsys_column.pwccol2-longformc .parsys_column .text-component,.parsys_column.pwccol3-longform .parsys_column .text-component {
        min-width:330px
    }
}

@media(min-width: 640px) and (max-width:767px) {
    .parsys_column.pwccol1-longform .parsys_column .text-component,.parsys_column.pwccol1-longform-left .parsys_column .text-component,.parsys_column.pwccol2-longform .parsys_column .text-component,.parsys_column.pwccol2-longformb .parsys_column .text-component,.parsys_column.pwccol2-longformc .parsys_column .text-component,.parsys_column.pwccol3-longform .parsys_column .text-component {
        min-width:384px
    }
}
.footer-link{
    color: grey !important;
    cursor: pointer;
    text-decoration: underline;

}
.parsys.sectionpar {
    font-size:14px;
    color: #696969;

}
.text-component {
    margin-left: 17px;
}
.footer-link-details {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0rem;
  }
  .footer-link-details li {
    display: inline;
    margin-left:10px
}
.text .text-component ul,.text .text-component ol,.textimage .textimage-text ul,.textimage .textimage-text ol,.text.is-edited {
    display: block;
    padding-left: 30px
}

.text .text-component ul ul,.text .text-component ul ol {
    padding-left: 25px
}

.text .text-component ol ul,.text .text-component ol ol {
    padding-left: 32px
}

.text-component ul,.text-component ol {
    margin-bottom: 10px;
    padding-left: 20px
}

.text-component ul ul,.text-component ul ol,.text-component ol ul,.text-component ol ol {
    margin-top: 15px
}

.text-component ul ul li,.text-component ul ol li,.text-component ol ul li,.text-component ol ol li {
    margin-bottom: 11px
}

.text-component ul li p {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: top
}

.text-component .btn {
    max-width: 64ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

@media screen and (max-width: 468px) {
    .text-component .btn {
        max-width:100%
    }
}

.text-component p .btn.btn--transparent {
    margin-right: 25px
}

.text-component p .btn.btn--transparent.btn-cta {
    color: #fff;
    border: 0
}

@media(min-width: 1200px) {
    .text-component p .btn.btn--transparent.btn-cta {
        min-height:49px
    }
}

.text-component p .btn.btn--transparent.btn-cta.btn-cta--chevron i {
    content: '';
    width: 8px;
    height: 12px;
    display: inline-block;
    background-repeat: no-repeat;
    margin-left: 10px;
    transition: all .3s ease-in-out
}

.text-component p .btn.btn--transparent.btn-cta:hover i,.text-component p .btn.btn--transparent.btn-cta:focus i {
    margin-left: 14px
}

.text-component p .btn.btn--transparent.btn-cta.btn-cta--download i {
    content: '';
    width: 14px;
    height: 14px;
    display: inline-block;
    background-repeat: no-repeat;
    margin-left: 10px;
    transition: all .3s ease-in-out
}

.text-component p .btn.btn--transparent.btn-cta.btn-cta--external i {
    content: '';
    width: 14px;
    height: 14px;
    display: inline-block;
    background-repeat: no-repeat;
    margin-left: 10px;
    transition: all .3s ease-in-out
}

.text-component p .btn.btn--transparent.btn-cta.btn-cta--red {
    background-color: #e0301e
}

.text-component p .btn.btn--transparent.btn-cta.btn-cta--orange {
    background-color: #d04a02
}

.text-component p .btn.btn--transparent.btn-cta.btn-cta--digitalrose {
    background-color: #d93954
}

.text-component p .btn.btn--transparent.btn-cta.btn-cta--white {
    background-color: #fff;
    color: #2d2d2d;
    border: 1px solid #7d7d7d
}

.text-component p .btn.btn--transparent.btn-cta.btn-cta--burgundy {
    background-color: #a32020
}

.page-primary-col--orange .text-component p .btn.btn--transparent.btn-cta.btn-cta--white:hover,.page-primary-col--orange .text-component p .btn.btn--transparent.btn-cta.btn-cta--white:focus,.page-primary-col--orange .text-component p .btn.btn--transparent.btn-cta.btn-cta--white:active {
    background-color: #d04a02;
    color: #fff;
    border: 0
}

.page-primary-col--red .text-component p .btn.btn--transparent.btn-cta.btn-cta--white:hover,.page-primary-col--red .text-component p .btn.btn--transparent.btn-cta.btn-cta--white:focus,.page-primary-col--red .text-component p .btn.btn--transparent.btn-cta.btn-cta--white:active {
    background-color: #e0301e;
    color: #fff;
    border: 0
}

.page-primary-col--burgundy .text-component p .btn.btn--transparent.btn-cta.btn-cta--white:hover,.page-primary-col--burgundy .text-component p .btn.btn--transparent.btn-cta.btn-cta--white:focus,.page-primary-col--burgundy .text-component p .btn.btn--transparent.btn-cta.btn-cta--white:active {
    background-color: #a32020;
    color: #fff;
    border: 0
}

.page-primary-col--rose .text-component p .btn.btn--transparent.btn-cta.btn-cta--white:hover,.page-primary-col--rose .text-component p .btn.btn--transparent.btn-cta.btn-cta--white:focus,.page-primary-col--rose .text-component p .btn.btn--transparent.btn-cta.btn-cta--white:active {
    background-color: #d93954;
    color: #fff;
    border: 0
}

.text-component p .btn.btn--transparent.btn-cta:hover,.text-component p .btn.btn--transparent.btn-cta:focus,.text-component p .btn.btn--transparent.btn-cta:active {
    color: #2d2d2d;
    background-color: #fff;
    border: 1px solid #7d7d7d
}

.text-component p .btn.btn--transparent.btn-cta--chevron i {
    content: '';
    width: 8px;
    height: 12px;
    display: inline-block;
    background-repeat: no-repeat;
    margin-left: 10px;
    transition: all .3s ease-in-out
}



.text-component p .btn.btn--transparent.btn-cta--chevron:hover i,.text-component p .btn.btn--transparent.btn-cta--chevron:focus i {
    margin-left: 14px
}





.text-component p .btn.btn--transparent.btn-cta--download i {
    content: '';
    width: 14px;
    height: 14px;
    display: inline-block;
    background-repeat: no-repeat;
    margin-left: 10px;
    transition: all .3s ease-in-out
}



.text-component p .btn.btn--transparent.btn-cta--download:hover i,.text-component p .btn.btn--transparent.btn-cta--download:focus i {
    margin-left: 14px
}

.text-component p{
    margin-top: 1%;
    font-size: 14px;
    line-height: 1.7;
    font-weight: 400;
    text-align: justify;
    color: rgb(125,125,125);


}
.text-component h2 {
    font-size: 22px;
    font-weight: 500;
    color: rgb(45,45,45);

}
.text-component h3{
    font-size: 22px;
    font-weight: 500;
    color: rgb(45,45,45);

}
.text-component h4{
    font-size: 22px;
    font-weight: 500;
    color: rgb(45,45,45);

}

.link-explorer__intro-heading{
    font-size: 22px;
    font-weight: 500;
    color: rgb(45,45,45);

}
.parsys.sectionpar.alignment {
    margin-right: 3%;
}
.link-explorer__link-text{
    margin-top: 1%;
    font-size: 14px;
    line-height: 1.7;
    font-weight: 400;
    text-align: justify;
    color: rgb(125,125,125);
}
.headers{
    font-size: 16px;
    font-weight: 500;
    color: rgb(45,45,45);
}
.link-explorer__intro-copy{
    font-size: 14px;
    line-height: 1.7;
    font-weight: 400;
    text-align: justify;
    color: rgb(125,125,125);

}


