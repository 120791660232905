.wt_label1 {
  color: #252525;
  font-size: 18px;
}

.wt_label2 {
  color: #696969;
  & > b {
    font-weight: bold;
  }
  & > .icon-home-fill {
    cursor: pointer !important;
  }
}

.wt_label3 {
  color: #e0301e;
}

.ap-breadcrumb {
  padding: 10px 0;
}

.header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
}

.top-icons {
  display: flex;
  justify-content: flex-end;
}
.top-icon {
  margin: 8px !important;
  cursor: pointer;
  padding: 20px;
  border-radius: 50%;
  background-color: #464646;
  position: relative;
  color: #fff;
}
.headertext {
  margin-top: 15px;
  font-size: 9px;
  margin-right: 5px;
}
.counter {
  width: 13px;
  height: 13px;
  background-color: #e0301e;
  border-radius: 50%;
  margin: 3px 8px 0 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #ffffff;
}
.notifications {
  position: absolute;
  top: 50px;
  right: 15px;
  background-color: #f3f3f3;
  color: #474747;
  font-family: "PwC Helvetica Neue";
  font-size: 14px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: 300px;
  box-shadow: 0px 3px 6px #0000001a;
}

.notification-content {
  overflow: auto;
  height: auto;
  max-height: 300px;
}

#scrollStyle::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
#scrollStyle::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
#scrollStyle::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

#scrollStyle::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.notification {
  padding: 10px 15px 10px 20px;
  cursor: pointer;
  border-bottom: #696969;
  background: #e8e8e8;
  width: 100%;
  display: flex;
}
.notificationRead {
  padding: 10px 15px 10px 20px;
  border-bottom: #696969;
  background-color: #fff;
  pointer-events: none;
  width: 100%;
  display: flex;
}
.unreadDot {
  display: flex;
  background-color: #e0301e !important;
  border-radius: 50% !important;
  width: 10px !important;
  height: 10px !important;
  padding: 0;
  margin-top: 2%;
}
.dateStyle {
  font-size: 12px !important;
  color: #696969;
  float: right;
  padding-left: 10px;
}
.dateStyleRead {
  font-size: 12px !important;
  color: #696969;
  float: right;
  margin-right: -6%;
}
.notification:hover {
  color: #474747;
  background: #f3f3f3;
}
.notificationHeader {
  background-color: #e0301e;
  color: #ffffff;
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
}
.notifyStyle {
  font-family: "PwC Helvetica Neue";
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0;
}

.count {
  font-size: 12px;
}

.breakLine {
  margin: 0rem !important;
}
