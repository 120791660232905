.headers{
    font-size: 16px;
    font-weight: 500;
    color: rgb(45,45,45);
}
.entireContent_Credits{
    margin-left: 2.1%;
    margin-right: 3%;
}
.bodyCredits{
    margin-top: 1%;
    font-size: 14px;
    line-height: 1.7;
    font-weight: 400;
    text-align: justify;
    color: rgb(125,125,125);
}
.endContent{
    margin-top: 2%;
}
.anchorText{
    color: rgb(45,45,45);
    font-weight: 400;
    cursor: pointer;
    margin-top: 20%;
}
.disabledAnchor{
    color: rgb(45,45,45);
    font-weight: 400;
    cursor: pointer;
    margin-top: 20%;
    pointer-events: none;
    opacity: 0.5;
}