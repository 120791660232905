.nav-breadcrumb {
  width: auto;
  cursor: pointer;
  margin-top: 2px;
}

.bc-label {
  width: 100px;
  text-align: left;
  cursor: pointer;
}

.nav-breadcrumb * .btn-secondary {
  --bs-btn-border-color: transparent !important;
  --bs-btn-color: black !important;
  --bs-btn-bg: transparent !important;
}
