@import '/node_modules/sass-rem/_rem.scss';
@import '/node_modules/@appkit4/react-components/styles/scss/_variables';

.ap-navigation-demo-wrapper {
    background-color: $color-background-default;
}

.ap-navigation-item.selected,
.ap-navigation-item.active {
    // custom styles for the selected item
    font-weight: $font-weight-2;
    background-color: $color-background-hover-selected  !important;


}

.hideNavItem{
    display: none !important;
    margin: 0 !important;
}

.ap-navigation-wrapper .ap-nav-item.nav-with-child.nav-item-expanded > .nav-item-child{
    min-height: 0 !important;
}
.ap-navigation-wrapper.sitedemo {
    background-color: #2D2D2D;
    height: 100%;
}

.align-bottom-mar{
    vertical-align: bottom !important;
    margin-top: 4.5px;
    width:250px
}

.sitedemo{
    overflow-x: hidden;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
}


.ap-navigation-wrapper .ap-navigation-item .itemContent {
    color: #fff;
    // font-weight: bold;
    // font-family: Open Sans;
    font-family:"PwC Helvetica Neue";
    font-size:16px;
    line-height: 1.4em;

}

.ap-navigation-wrapper .ap-navigation-heading .ap-navigation-title-wrapper .ap-navigation-logo {
    // -webkit-mask-image: url(../images/pwc-logo-white.svg) !important;
     background-color: #fff;
     margin-top: 15px;
    // height: 58px;
    width: 60px;
    margin-left: 20px;
}
.ap-navigation-wrapper .ap-navigation-heading{
    padding: 0px !important;
    align-items: flex-start !important;
    flex-direction: column;
}
.ap-navigation-avatar-content{
    padding: 0 20px 0 20px;
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.user-name{
    cursor: pointer;
    padding-left: 15px;
    .title{
        color: #fff;
        font-family:"PwC Helvetica Neue";
        font-size:16px;
        line-height: 1.4em;
        font-weight:"Bold";
    }
    .subtitle{
        color: #707070;
        font-family: "PwC Helvetica Neue";
        font-size:14px;
        line-height: 1.7em;
        font-weight:"Normal";
    }
}

.ap-navigation-item.selected,
.ap-navigation-item.active {
    background-color: #e0301e !important;
}

.prefixIcon,
.suffixIcon {
    color: #fff;
}

.ap-header-title {
    font-family:"PwC Helvetica Neue";
    font-size:18px;
    line-height: 1.4em;
    margin-left: 15px;
    font-weight:"Bold";
}

.ap-navigation-wrapper .ap-navigation-heading .ap-navigation-title-wrapper .ap-navigation-title {

    margin-top: 15px;
    padding-left: 0;
    // border-left: 2px solid #fff
}

.container-sidenav{
    // margin-left: 250px;
    // transition: margin-left .2s ease-in;
    // padding: 35px;
    // box-sizing: border-box;
    width:900px;
    margin-left: 250px;
}

// .container-sidenav.inactive{
//     margin-left: 60px;
// }

/* css */
@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
body {
    background: #fafafa;
}

p {
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #202020;
    color: #fff;
    transition: all 0.3s;
}

#sidebar.active {
    margin-left: -250px;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #e0301e;
}

#sidebar ul.components {
    padding: 20px 0;
    
}

.nav-disabled{
    opacity: 0.5;
    pointer-events: none;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}

#sidebar ul li a:hover {
    color: #7386D5;
    background: #fff;
}

.app-logo{
  font-size: x-small;
  font-weight: 300;
}

.logo-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

// .ap-navigation-wrapper .ap-navigation-footer{
//   height: 3.5rem !important;
// }

// .ap-navigation-footer .ap-navigation-item.collapsed-item{
//     height: 2rem !important;
// }

.ap-navigation-footer{
    display: none !important;
}

.ap-navigation-wrapper .ap-navigation-item{
    height: 2.5rem !important;
}
.ap-navigation-wrapper .ap-navigation-content{
    padding: 0.4rem var(--spacing-4) 4.5rem;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background: #e0301e;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}

ul.CTAs {
    padding: 20px;
}

.ap-footer{
    display: flex;
    font-size: 0.7rem;
    justify-content: space-between;
}

.ap-footer-link-group{
    display: flex;
    flex-direction: row;
    width: 40%;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article,
a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    overflow-x: hidden;
    min-height: 100vh;
    margin-bottom: 55px;
    transition: all 0.3s;
    background-color: #f3f3f3;
}
/*Navigation Code */
.ap-navigation-wrapper .ap-navigation-content {
    height: calc(100% - 7.5rem);
    }
    
/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
    #sidebarCollapse span {
        display: none;
    }
}

.ap-navigation-wrapper .ap-navigation-item:hover {
    background-color: #474747 ;
    }